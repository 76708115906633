import React from 'react';
export default function returnImage(props) {
    let Image = props.details.Image
    let characterName = props.details.characterName
    let characterImage = require("../pages/images/characters/"+Image)
    let link = '/character/'+characterName
   
    return (

        <a href={link}> <img className="usr-char-image" src={characterImage}></img></a>

        )
}