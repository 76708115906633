import React from 'react';
import logo from './logo.svg';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Playersheet from './pages/Playersheet';
import Playersheetnew from './pages/Playersheetnew';
import Usersheet from './pages/Usersheet';
import DMSheet from './pages/DMSheet';
import Home from './pages/Home';
import Testrest from './pages/testrest';
import NewUser from './pages/NewUser'
function App() {
  return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/user/:username" component={Usersheet} />
          <Route exact path="/campaign/:campaign" component={DMSheet} />
          <Route exact path="/testrest/test" component={Testrest} />
          <Route exact path="/newUser" component={NewUser} />
          {/* <Route exact path="/:playerName" component={Playersheet} /> */}
          <Route exact path="/character/:playerName" component={Playersheetnew} />
          <Route path="/" component={ErrorPage} />
        </Switch>
      </Router>
  );
}

export default App;

function ErrorPage(){
  return(
    <h1 className="theme-h1-centered">
      404:<small>Not Found</small>
    </h1>
  )
}