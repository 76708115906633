import React from 'react';
import { Link } from 'react-router-dom';
import ComponentImage from '../components/characterimage';
import DMImage from '../components/dmimage';


function buildCharacterShortStat(characters) {
    let returnList = []
    for (let characterName of characters) {
        let characterSheet = require("../Characters/" + characterName + ".json")
        returnList.push(
            {
                "Name": characterSheet.CharacterName,
                "Level": characterSheet.Level,
                "Class": characterSheet.Class,
                "HP": characterSheet.HitPoints,
                "Image": characterSheet.Image,
                "Stats": characterSheet.Stats
            }
        )
    }

    return (returnList)

}




export default function Playersheet(props) {
    let campaign = props.match.params.campaign
    const campaignData = require("../campaigns/" + campaign + ".json")
    let campaignCharacters = campaignData.Characters
    let allCharacterStats = buildCharacterShortStat(campaignCharacters)

    return (
        <>
            <h1 className="theme-h1-user-page">{campaign}</h1>

            {
                allCharacterStats.map((char, i) => {
                    let tempData = { "Image": char.Image, "characterName": char.Name }

                    return (
                        <div className='char-container' key={"char-"+i}>
                            <ComponentImage details={tempData} />
                            <h2 className="theme-h2-user-page">{char.Name} - {char.Level} {char.Class}</h2>
                            <table className="char-stat-table">
                                <thead>
                                    <tr>
                                        <th className="theme-th">HP</th>
                                        <th className="theme-th">Armor Class</th>
                                        <th className="theme-th">Speed</th>
                                        <th className="theme-th">Initiative</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="theme-td">{char.HP}</td>
                                        <td className="theme-td">{char.Stats.ArmorClass}</td>
                                        <td className="theme-td">{char.Stats.Speed}</td>
                                        <td className="theme-td">{char.Stats.Initiative}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                })
            }
        </>
    )
}