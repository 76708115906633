import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

export default function Home(props) {
    let rollImage = require("../pages/images/letsroll.png")
    const [name, setName] = useState("")
    const [pass, setPass] = useState("")
    const [showError, setShowError] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [redirectNewUser, setRedirectNewUser] = useState(false)
    const checkOrRejectPath = () => {
        console.log("check or Reject")
        try {
            console.log("looking for user name " + name + " oo")
            let userObject = require("../users/" + name + ".json");
            console.log("username")
            console.log(userObject.UserName)
            if(userObject.UserName == name){
                if(userObject.Password == pass){
                    setShowError(false)
                    setRedirect(true)
                }
                else{
                    setShowError(true)
                }
            }
            else{
                setShowError(true)
            }
        } catch (err) {
            
        }
    }
    return (
        <>
            {//if nameis correct redirect
                redirectNewUser ? <Redirect to={"/newUser/"} /> : <></>
            }
            {//if nameis correct redirect
                redirect ? <Redirect to={"/user/" + name} /> : <></>
            }
            <h1 className="theme-h1-user-page">Welcome to Nat 1</h1>
            <p className="theme-p-centered">where all your dreams never come true...</p>
            {//if name is incorrect show error
                showError ?
                    <p className="theme-p-centered" style={{ color: "red" }}>Incorrect credentails mortal!</p>
                    : <></>
            }
            <div style={{ marginTop: "180px", display: "flex", flexDirection: "column",justifyContent:"center", width: "250px", marginLeft:"auto", marginRight:"auto" }}>
                <div>
                    <p className="theme-p-centered">Type your name mortal:</p>
                    <div className="form-holder" style={{ marginLeft:"20px", marginBottom: "10px"}}>
                        <input id="uname" className="login-input" value={name} onChange={(e) => { setName(e.target.value) }} />
                    </div>
                </div>
                <div>
                    <p className="theme-p-centered">Password: </p>
                    <div className="form-holder" style={{ marginLeft:"20px"}}>
                        <input type="password" id="pwd" className="login-input" value={pass} onChange={(e) => { setPass(e.target.value) }} />
                    </div>
                </div>

            </div>

            <div style={{ marginTop: "10px", display: "flex", flexDirection: "row",justifyContent:"center", width: "250px", marginLeft:"auto", marginRight:"auto" }}>
                <span className="butt-ton" style={{marginLeft:"auto", marginRight:"auto"}} onClick={()=>{checkOrRejectPath()}}>&nbsp;Log In&nbsp;</span>
            </div>    
            <div style={{ marginTop: "10px", display: "flex", flexDirection: "row",justifyContent:"center", width: "250px", marginLeft:"auto", marginRight:"auto" }}>
                <span className="butt-ton" style={{marginLeft:"auto", marginRight:"auto"}} onClick={()=>{setRedirectNewUser(true)}}>&nbsp;Create User&nbsp;</span>
            </div>
        </>

    )
}