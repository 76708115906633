import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as fasStar, faInfinity } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
/*
spell slot not rerendering ?
pop up lvlup light box SAVE to state
pop up spell light box SAVE to state

use api
save

new char -appearance
new user 
*/
function getKeyValueList(Stats) {
    let returnObject = {}
    let keys = []
    let values = []
    for (const [key, value] of Object.entries(Stats)) {
        keys.push(`${key}`)
        values.push(`${value}`)
    }
    returnObject.keys = keys
    returnObject.values = values

    return (
        returnObject
    )
}


function getHighestLevelSpell(Spellslots) {

    let highestlevel = 0
    let intCurrentSpellslot = 0
    let currentSpellslot = ""
    for (const [key, value] of Object.entries(Spellslots)) {
        currentSpellslot = `${key}`
        intCurrentSpellslot = parseInt(currentSpellslot, 10)
        if (intCurrentSpellslot > highestlevel) {
            highestlevel = intCurrentSpellslot
        }

    }
    return (
        highestlevel
    )
}

export default function Playersheetnew(props) {
    const [pageIdx, setPageIdx] = useState(0);
    const [lightBoxIdx, setLightBoxIdx] = useState(999);
    const [playershetObj, setCurHplayershetObjP] = useState({});//set as empty object
    const [throws, setThrows] = useState([]);
    const [skills, setSkills] = useState([]);
    const [spells, setSpells] = useState([]);
    const [spellSlots, setSpellSlots] = useState([]);
    const [notes, setNotess] = useState([]);
    const [gear, setGear] = useState([]);
    const [showLB, setShowLB] = useState(false);
    const [mainState, setMainState] = useState({})
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        // add GET request


        //let player = JSON.parse(myJson.body)
        // Update the document title using the browser API
        setCurHplayershetObjP(player)
        setMainState(player)
        setThrows(player.SavingThrows)
        setSkills(player.Skills)
        setNotess(player.Notes)
        setGear(player.Gear)
        setSpellSlots(player.SpellSlots)
        setSpells(player.Spells)
        console.log("z")
        setPageIdx(0)
    }, [playershetObj]);

    let playername = props.match.params.playerName
    const player = require("../Characters/" + playername + ".json")

    let playerStats = getKeyValueList(player.Stats)
    let HighestLevelSpell = getHighestLevelSpell(player.SpellSlots)
    // let allSpells = getAllSpells(player.Spells,player.SpellSlots)
    let SpellLevelList = []

    for (let index = 1; index <= HighestLevelSpell; index++) {
        SpellLevelList.push(index)
    }

    //get the image
    const imageFileName = player.Image
    let characterImage = require("./images/characters/" + player.Image)
    const setProficiantSkill = (i, name) => {
        const s = [...mainState.Skills]
        s[i].Proficient = !s[i].Proficient
        setSkills(s)
        updatePlayer()
    }
    const setProficiantThrow = (i, name) => {
        const s = [...mainState.SavingThrows]
        s[i].Proficient = !s[i].Proficient
        setThrows(s)
        updatePlayer()
    }
    const updateSpells = (s) => {
        setSpells(s)
        updatePlayer()
    }
    const updateSpellSlots = (s) => {
        setSpellSlots(s)
        updatePlayer()
    }
    const updateNotes = (n) => {
        setNotess(n)
        updatePlayer()
    }
    const updateGear = (n) => {
        setGear(n)
        updatePlayer()
    }
    const updateHealth = (e) => {
        console.log('verifying health')
        let p = mainState
        let totalHitPoints = Number(p.HitPoints)
        if (Number.isNaN(hpBox.current.textContent)) {
            console.log('New inmput is not number')
            hpBox.current.innerText = p.currentHitPoints
        }
        else {
            if ((hpBox.current.textContent >= 0) && (hpBox.current.textContent <= totalHitPoints)) {
                console.log('valid number!')
                p.currentHitPoints = hpBox.current.textContent
                hpBox.current.innerText = p.currentHitPoints
                setMainState(p)
                console.log(p)
            }
            else {
                console.log('Numnbeer is not in valid health range')
                hpBox.current.innerText = p.currentHitPoints
            }
        }
    }
    const useSpellSlot = (level) => {
        let s = mainState
        if (s.SpellSlots[level].used == "0") {
            return
        }
        s.SpellSlots[level].used = s[level].used - 1
        setMainState(s)

    }

    const updatePlayer = () => {
        let p = mainState
        p.Skills = skills
        p.SavingThrows = throws
        p.Notes = notes
        p.Gear = gear
        p.Spells = spells
        setMainState(p)
        console.log(mainState)
    }
    const hpBox = useRef(null);
    console.log("Dean player")
    console.log(mainState)
    return (
        <body>
            <div style={{ display: "flex", justifyContent: "space-between" }} className="CharacterDetails">
                <img style={{ maxWidth: "100%", maxHeight: "400px" }} src={characterImage}></img>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ width: "100%", maxHeight: "400px" }}>
                        <div style={{ width: "500px", marginLeft: "auto", marginRight: "auto" }}>
                            <h1 className="theme-h1">{player.CharacterName} </h1>
                            <h2 className="theme-h2-centered">Level {player.Level} {player.Class}</h2>
                            <h2 className="theme-h2-centered" >
                                <span onBlur={(e) => { updateHealth("HP") }} contentEditable={true} ref={hpBox}>{player.currentHitPoints}</span>
                                <span>/{player.HitPoints}</span>
                            </h2>
                            <br />
                            <span style={{ display: "flex", justifyContent: "space-around" }} className="theme-h4-centered">
                                <span className="butt-ton" onClick={() => setPageIdx(2)}>Info</span>
                                <span className="butt-ton" onClick={() => setPageIdx(3)}>Notes</span>
                                <span className="butt-ton" onClick={() => setPageIdx(0)}>Spells</span>
                                <span className="butt-ton" onClick={() => setPageIdx(1)}>Gear</span>
                                <span className="butt-ton" onClick={() => { setShowLB(true); setLightBoxIdx(1) }}>+1</span>
                            </span>
                            {
                                isSaving ? <ShowSaving /> : <></>
                            }
                        </div>
                    </div>

                    <div style={{ width: "50%", maxHeight: "400px", display: "flex", justifyContent: "space-between", paddingRight: "10px" }}>

                    </div>
                </div>
            </div>
            <table className="char-stat-table">
                <tr>
                    {
                        playerStats.keys.map((title, i) => {
                            return (<th className="theme-th">{title}</th>)
                        })
                    }

                </tr>
                <tr>
                    {
                        playerStats.values.map((title, i) => {
                            return (<td className="theme-td">{title}</td>)
                        })
                    }

                </tr>
            </table>

            {
                mainState !== {} ?
                    <>
                        <PageSelector
                            id={pageIdx} setMainState={setMainState} player={mainState} p={player}
                            setShowLB={setShowLB} setLightBoxIdx={setLightBoxIdx} useSpellSlot={useSpellSlot} spells={spells} spellSlots={spellSlots} updateSpells={updateSpells} updateSpellSlots={updateSpellSlots}
                            gear={gear} gearMethod={updateGear}
                            throws={throws} skills={skills} setProficiantSkill={setProficiantSkill} setProficiantThrow={setProficiantThrow}
                            notes={notes} notesMethod={updateNotes}
                        />
                        <LightBox
                            idx={lightBoxIdx} showLB={showLB} f={setShowLB}
                            state={mainState}
                        />
                    </> :
                    <></>
            }

        </body>
    )

}


function PageSelector(props) {
    if (props.player === {}) {
        console.log("pageSellectFAILED")
        return <Loading />
    }
    console.log("pageSellect")
    console.log(props.player)//mainstate

    switch (props.id) {
        case 0:
            // props.player.Spells?props.player.Spells:props.p.Spells // player.SpellSlots?props.player.SpellSlots:props.p.SpellSlots
            return <SpellsPage spells={props.spells} slots={props.spellSlots} setShowLB={props.setShowLB} setLightBoxIdx={props.setLightBoxIdx}
                useSpellSlot={props.useSpellSlot} updateSpells={props.updateSpells}  updateSpellSlots={props.updateSpellSlots}/>
        case 1:
            return <GearPage gear={props.gear} gearMethod={props.gearMethod} />
        case 2:
            return <LookPage player={props.player} setProficiantSkill={props.setProficiantSkill} setProficiantThrow={props.setProficiantThrow} throws={props.throws} skills={props.skills} />
        case 3:
            return <NotesPage notes={props.notes} notesMethod={props.notesMethod} />
        default:
            return <Loading />
    }
}
function Loading() {
    return <h1>Loading</h1>
}
function SpellsPage(props) {
    const [spellFilter, setSpellFilter] = useState("");
    const [slotState, setSlotState] = useState({})
    // useEffect(() => {
    //     console.log("effecto")
    //     setSlotState(props.slots)
    // })
    if (props.slots.length == 0){
        return (<Loading/>)
    }
    const changeSlots=(l)=>{
        console.log("setting slot ")
        console.log(props.slots)
        console.log(slotState)
        const s = props.slots
        if (s[l].used != 0){
            s[l].used = s[l].used - 1
            setSlotState(s)
            props.updateSpellSlots(s)
        }
    }
    const resetSlots=()=>{
        const s =props.slots
        s.level1.used = s.level1.available
        s.level2.used = s.level2.available
        s.level3.used = s.level3.available
        s.level4.used = s.level4.available
        s.level5.used = s.level5.available
        s.level6.used = s.level6.available
        s.level7.used = s.level7.available
        s.level8.used = s.level8.available
        s.level9.used = s.level9.available
        setSlotState(s)
        props.updateSpellSlots(s)
    }
    const deleteSpell = (i) => {
        console.log("del spell")
        const s = [...props.spells];
        const spellsAr = s.filter((spell) => {
            if (spellFilter == "") {
                return true
            }
            if (spell.Name.toString().toLowerCase().includes(spellFilter.toString().toLowerCase())) {
                return true;
            } else if (spell.Level.toString().toLowerCase().includes(spellFilter.toString().toLowerCase())) {
                return true;
            } else {
                return false;
            }

        })
        if (window.confirm("You are hit with a bout of amnesia, " + spellsAr[i].Name + " will be forgotten.")) {
            let spellId = s.indexOf(spellsAr[i])
            s.splice(spellId, 1);
            props.updateSpells(s)
        }
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", alignContent: "center", textAlign: "center" }}>
                <div>
                    <span className="theme-h2-centered">Spells</span>
                    <span className="butt-ton" onClick={() => { props.setShowLB(true); props.setLightBoxIdx(0); console.log(slotState) }}>+</span>
                </div>
                <table >
                    <tr>
                        <th className="theme-th">level 1</th>
                        <th className="theme-th">level 2</th>
                        <th className="theme-th">level 3</th>
                        <th className="theme-th">level 4</th>
                        <th className="theme-th">level 5</th>
                        <th className="theme-th">level 6</th>
                        <th className="theme-th">level 7</th>
                        <th className="theme-th">level 8</th>
                        <th className="theme-th">level 9</th>
                        <th className="theme-th">reset</th>
                    </tr>
                    {
                        slotState == {}?
                        <tr></tr>
                        :
                    <tr>
                        <td className="theme-td"><span contentEditable={true}> {props.slots.level1.used} </span>/{props.slots.level1.available}<span className="butt-ton" onClick={() => { changeSlots("level1") }}>Use 1</span></td>
                        <td className="theme-td"><span contentEditable={true}> {props.slots.level2.used} </span>/{props.slots.level2.available}<span className="butt-ton" onClick={() => { changeSlots("level2") }}>Use 1</span></td>
                        <td className="theme-td"><span contentEditable={true}> {props.slots.level3.used} </span>/{props.slots.level3.available}<span className="butt-ton" onClick={() => { changeSlots("level3") }}>Use 1</span></td>
                        <td className="theme-td"><span contentEditable={true}> {props.slots.level4.used} </span>/{props.slots.level4.available}<span className="butt-ton" onClick={() => { changeSlots("level4") }}>Use 1</span></td>
                        <td className="theme-td"><span contentEditable={true}> {props.slots.level5.used} </span>/{props.slots.level5.available}<span className="butt-ton" onClick={() => { changeSlots("level5") }}>Use 1</span></td>
                        <td className="theme-td"><span contentEditable={true}> {props.slots.level6.used} </span>/{props.slots.level6.available}<span className="butt-ton" onClick={() => { changeSlots("level6") }}>Use 1</span></td>
                        <td className="theme-td"><span contentEditable={true}> {props.slots.level7.used} </span>/{props.slots.level7.available}<span className="butt-ton" onClick={() => { changeSlots("level7") }}>Use 1</span></td>
                        <td className="theme-td"><span contentEditable={true}> {props.slots.level8.used} </span>/{props.slots.level8.available}<span className="butt-ton" onClick={() => { changeSlots("level8") }}>Use 1</span></td>
                        <td className="theme-td"><span contentEditable={true}> {props.slots.level9.used} </span>/{props.slots.level9.available}<span className="butt-ton" onClick={() => { changeSlots("level9") }}>Use 1</span></td>
                        <td className="theme-td"><span className="butt-ton" onClick={() => { resetSlots() }}>Reset</span></td>
                    </tr>
                    }
                </table>
                <div className="form-holder" style={{ marginLeft:"0px",  marginLeft: "auto", marginRight: "auto" }}>
                    <input className="login-input" placeholder="filter"  onChange={(e) => { setSpellFilter(e.target.value) }}></input>
                </div>
            </div>
            {
                props.spells.length == 0 ?
                    <span className="theme-h4">No spells available </span>
                    :
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", flexDirection: "row" }}>
                        {
                            props.spells.filter((spell) => {
                                if (spellFilter == "") {
                                    return true
                                }
                                if (spell.Name.toString().toLowerCase().includes(spellFilter.toString().toLowerCase())) {
                                    return true;
                                } else if (spell.Level.toString().toLowerCase().includes(spellFilter.toString().toLowerCase())) {
                                    return true;
                                } else {
                                    return false;
                                }

                            }).map((spell, i) => {
                                return (
                                    <div style={{ width: "47%" }} className="card">
                                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", flexDirection: "row" }}>
                                            <div>
                                                <span className="theme-h4">{spell.Name} </span>
                                                <small className="tag">{spell.Level}</small>
                                            </div>
                                            <div>
                                                {
                                                    spell.Level !== "Cantrip" ?
                                                        <>
                                                            <span style={{ fontWeight: "bold" }}>
                                                                {props.slots["level" + spell.Level].used !== "" ? props.slots["level" + spell.Level].used : "0"}/
                                                        {props.slots["level" + spell.Level].available}
                                                            </span>
                                                            <span className="butt-ton" onClick={() => { changeSlots("level" + spell.Level) }}>Cast</span>
                                                        </>
                                                        : <FontAwesomeIcon icon={faInfinity} />
                                                }
                                                <span className="butt-ton" onClick={() => { deleteSpell(i) }}>X</span>
                                            </div>
                                        </div>

                                        <div>
                                            <table >
                                                <tr>
                                                    <th className="theme-th">Casting Time</th>
                                                    <th className="theme-th">Range/Area</th>
                                                    <th className="theme-th">Components</th>
                                                    <th className="theme-th">Duration</th>
                                                    <th className="theme-th">School</th>
                                                    <th className="theme-th">Attack/Save</th>
                                                    <th className="theme-th">Damage/Effect</th>
                                                </tr>
                                                <tr>
                                                    <td className="theme-td">{spell.CastingTime}</td>
                                                    <td className="theme-td">{spell.RangeArea}</td>
                                                    <td className="theme-td">{spell.Components}</td>
                                                    <td className="theme-td">{spell.Duration}</td>
                                                    <td className="theme-td">{spell.School}</td>
                                                    <td className="theme-td">{spell.AttackSave}</td>
                                                    <td className="theme-td">{spell.DamageEffect}</td>
                                                </tr>
                                            </table>
                                            <p>
                                                {spell.Details}
                                            </p>
                                        </div>

                                    </div>
                                )
                            })

                        }
                    </div>
            }
        </>
    )
}
function GearPage(props) {
    const newGear = () => {
        console.log("NewGear")
        const g = [...props.gear]
        g.push(
            {
                Idx: g.length + 1,
                Title: "",
                Body: ""
            }
        )
        props.gearMethod(g)
    }
    const updateGear = (i, f, e) => { //index and feild event
        const g = [...props.gear];
        g[i][f] = e.target.innerText

        props.gearMethod(g);
    }
    const deleteGear = (i) => {
        if (window.confirm("You drop this gear on the ground, it loved you but you abandoned it.")) {
            const g = [...props.gear];
            g.splice(i, 1);
            props.gearMethod(g);
        }
    }

    return (
        <>
            <h2 className="theme-h2-centered">Gear<span className="butt-ton" onClick={() => { newGear() }}>+</span></h2>
            <div style={{ display: "flex", justifyContent: "space-between", marginRight: "10px", flexWrap: "wrap", flexDirection: "row" }}>
                {
                    props.gear.map((x, i) => {
                        return (
                            <div style={{ width: "40%" }}>
                                <CoolText key={"gear-" + i} title={x.Title} content={x.Body} updateFunc={updateGear} deleteFunc={deleteGear} i={i} />
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}
function LookPage(props) {
    let player = props.player;
    return (
        <>
            <h2 className="theme-h2-centered">Info</h2>
            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
                <div style={{ width: "50%" }}>
                    <h2 className="theme-h2-centered">Apearance</h2>
                    <table style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}>
                        <tr>
                            <th className="theme-th">Age</th>
                            <td className="theme-td">{player.Character.Age}</td>
                        </tr>
                        <tr>
                            <th className="theme-th">Height</th>
                            <td className="theme-td">{player.Character.Height}</td>
                        </tr>
                        <tr>
                            <th className="theme-th">Weight</th>
                            <td className="theme-td">{player.Character.Weight}</td>
                        </tr>
                        <tr>
                            <th className="theme-th">Eyes</th>
                            <td className="theme-td">{player.Character.Eyes}</td>
                        </tr>
                        <tr>
                            <th className="theme-th">Skin</th>
                            <td className="theme-td">{player.Character.Skin}</td>
                        </tr>
                        <tr>
                            <th className="theme-th">Hair</th>
                            <td className="theme-td">{player.Character.Hair}</td>
                        </tr>
                        <tr>
                            <th className="theme-th">Class</th>
                            <td className="theme-td">{player.Class}</td>
                        </tr>
                        <tr>
                            <th className="theme-th">Background</th>
                            <td className="theme-td">{player.Character.Background}</td>
                        </tr>
                        <tr>
                            <th className="theme-th">Race</th>
                            <td className="theme-td">{player.Character.Race}</td>
                        </tr>
                        <tr>
                            <th className="theme-th">Alignment</th>
                            <td className="theme-td">{player.Character.Alignment}</td>
                        </tr>
                    </table>
                </div>
                <div style={{ width: "50%", display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
                    <div style={{ width: "50%" }}>
                        <h2 className="theme-h2">Saving Throws</h2>
                        {
                            props.throws.sort((a, b) => (a.Name > b.Name) ? 1 : -1).map((s, i) => {
                                return (
                                    <StarPower item={s} idx={i} method={props.setProficiantThrow} />
                                )
                            })
                        }
                    </div>
                    <div style={{ width: "50%" }}>
                        <h2 className="theme-h2">Skills</h2>
                        {
                            props.skills.sort((a, b) => (a.Name > b.Name) ? 1 : -1).map((s, i) => {
                                return (
                                    <StarPower item={s} idx={i} method={props.setProficiantSkill} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </>
    )
}
function NotesPage(props) {
    const newNote = () => {
        console.log("NewNote")
        const n = [...props.notes]
        console.log(n)
        n.push(
            {
                Idx: n.length + 1,
                Title: "",
                Body: ""
            }
        )
        console.log(n)
        props.notesMethod(n)
    }
    const updateNote = (i, f, e) => { //index and feild event
        const n = [...props.notes];
        n[i][f] = e.target.innerText

        props.notesMethod(n);
    }
    const deleteNote = (i) => {
        if (window.confirm("Do you realllllllly wanna delete this... you call this a note?")) {
            const n = [...props.notes];
            n.splice(i, 1);
            props.notesMethod(n);
        }
    }

    return (
        <>
            <h2 className="theme-h2-centered">Notes<span className="butt-ton" onClick={() => { newNote() }}>+</span></h2>
            <div style={{ display: "flex", justifyContent: "space-between", marginRight: "10px", flexWrap: "wrap", flexDirection: "row" }}>
                {
                    props.notes.map((x, i) => {
                        return (
                            <div style={{ width: "40%" }}>
                                <CoolText key={"note-" + i} title={x.Title} content={x.Body} updateFunc={updateNote} deleteFunc={deleteNote} i={i} />
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}
function CoolText(props) {
    return (
        <div style={{ width: "100%" }} className="card">
            <div style={{ display: "flex", justifyContent: "space-between" }} >
                <h3 className="theme-h3-centered" style={{ display: "inline-block", minWidth: "200px" }} contentEditable={true} onBlur={(e) => { props.updateFunc(props.i, "Title", e) }}>{props.title}</h3>  <span className="butt-ton" onClick={() => { props.deleteFunc(props.i) }}>X</span>
            </div>
            <div><pre contentEditable={true} onBlur={(e) => { props.updateFunc(props.i, "Body", e) }}>{props.content}</pre></div>
        </div>
    )
}

function StarPower(props) {
    return (
        <div style={{ display: "flex", justifyContent: "space-between", color: "white", width: "200px" }}>
            <label>
                <span>{props.item.Proficient ? <FontAwesomeIcon icon={fasStar} /> : <FontAwesomeIcon icon={faStar} />}</span> &nbsp;
                <input type="checkbox" checked={props.item.Proficient} onChange={() => { props.method(props.idx, props.item.Name) }} style={{ display: "none" }} />
            </label>
            <span>{props.item.Name}</span>
        </div>
    )
}

function LightBox(props) {
    return (
        <div className={props.showLB ? "outerLB" : "none"} onClick={() => { props.f(!props.showLB) }}>
            <div className="innerLB" onClick={(e) => { e.stopPropagation() }}>
                <LightBoxSelector page={props.idx} state={props.state} />
            </div>
        </div>
    )
}

function LightBoxSelector(props) {
    switch (props.page) {
        case 0:
            return <SpellsLB />
        case 1:
            return <LevelUpLB state={props.state} />
        default:
            return <SpellsLB />
    }
}

function SpellsLB(props) {
    return (

        <>
            <h2 className="theme-h2">New Spell</h2>
            <table >
                <tr>
                    <th className="theme-th">Name</th>
                    <td className="theme-td"><div contentEditable={true} style={{ Width: "100px" }}>&nbsp;</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Level</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>&nbsp;</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Casting Time</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>&nbsp;</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Range/Area</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>&nbsp;</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Components</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>&nbsp;</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Duration</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>&nbsp;</div></td>
                </tr>
                <tr>
                    <th className="theme-th">School</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>&nbsp;</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Attack/Save</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>&nbsp;</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Damage/Effect</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>&nbsp;</div></td>
                </tr>
            </table>
            <p>Details</p>
            <div contentEditable={true} style={{ minHeight: "50px" }}>&nbsp;</div>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                &nbsp;
                <span className="butt-ton" >Save</span>
            </div>

        </>
    )

}
function LevelUpLB(props) {
    console.log(props.state)
    return (
        <>
            <h2 className="theme-h2">Level Up</h2>
            <table >
                <tr>
                    <th className="theme-th">SpellSaveDC</th>
                    <td className="theme-td"><div contentEditable={true} style={{ Width: "100px" }}>{props.state.Stats.SpellSaveDC}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">ProficiencyBonus</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>{props.state.Stats.ProficiencyBonus}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">ArmorClass</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>{props.state.Stats.ArmorClass}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Speed</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>{props.state.Stats.Speed}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Initiative</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>{props.state.Stats.Initiative}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Strength</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>{props.state.Stats.Strength}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Dexterity</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>{props.state.Stats.Dexterity}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Constitution</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>{props.state.Stats.Constitution}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Intelligence</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>{props.state.Stats.Intelligence}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Wisdom</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>{props.state.Stats.Wisdom}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Charisma</th>
                    <td className="theme-td"><div contentEditable={true} style={{ minWidth: "100px" }}>{props.state.Stats.Charisma}</div></td>
                </tr>
            </table>
            <h3 className="theme-h3">Spell Slots</h3>
            <table >
                <tr>
                    <th className="theme-th">Level 1</th>
                    <td className="theme-td"><div contentEditable={true} style={{ Width: "100px" }}>{props.state.SpellSlots.level1.available}</div></td>
                    <th className="theme-th">Level 2</th>
                    <td className="theme-td"><div contentEditable={true} style={{ Width: "100px" }}>{props.state.SpellSlots.level2.available}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Level 3</th>
                    <td className="theme-td"><div contentEditable={true} style={{ Width: "100px" }}>{props.state.SpellSlots.level3.available}</div></td>
                    <th className="theme-th">Level 4</th>
                    <td className="theme-td"><div contentEditable={true} style={{ Width: "100px" }}>{props.state.SpellSlots.level4.available}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Level 5</th>
                    <td className="theme-td"><div contentEditable={true} style={{ Width: "100px" }}>{props.state.SpellSlots.level5.available}</div></td>
                    <th className="theme-th">Level 6</th>
                    <td className="theme-td"><div contentEditable={true} style={{ Width: "100px" }}>{props.state.SpellSlots.level6.available}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Level 7</th>
                    <td className="theme-td"><div contentEditable={true} style={{ Width: "100px" }}>{props.state.SpellSlots.level7.available}</div></td>
                    <th className="theme-th">Level 8</th>
                    <td className="theme-td"><div contentEditable={true} style={{ Width: "100px" }}>{props.state.SpellSlots.level8.available}</div></td>
                </tr>
                <tr>
                    <th className="theme-th">Level 9</th>
                    <td className="theme-td"><div contentEditable={true} style={{ Width: "100px" }}>{props.state.SpellSlots.level9.available}</div></td>
                    <th></th>
                    <td></td>

                </tr>


            </table>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                &nbsp;
                <span className="butt-ton" >Save</span>
            </div>

        </>)
}


function ShowSaving() {
    return (
        <h1 className="theme-h1" style={{ color: "magenta" }}> SAVING...</h1>
    )
}