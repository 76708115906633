import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ComponentImage from '../components/characterimage';
import DMImage from '../components/dmimage';


function buildCharacterShortStat(characters) {
    let returnList = []
    for (let characterName of characters) {
        let characterSheet = require("../Characters/" + characterName + ".json")
        returnList.push(
            {
                "Name": characterSheet.CharacterName,
                "Level": characterSheet.Level,
                "Class": characterSheet.Class,
                "HP": characterSheet.HitPoints,
                "Image": characterSheet.Image,
                "Stats": characterSheet.Stats
            }
        )
    }

    return (returnList)

}



export default function Playersheet(props) {
    let userName = props.match.params.username
    const user = require("../users/" + userName + ".json")
    let characters = user.Characters
    let allplayercharcters = []
    let allDMCharacters = []
    const [redirectNewChar, setredirectNewChar] = useState(false)
    for (let index in characters) {

        let characterName = characters[index].Name
        let isDungeonMaster = characters[index].DungeonMaster

        if (isDungeonMaster == 'true') {
            allDMCharacters.push(characterName)
        }
        else {
            allplayercharcters.push(characterName)
        }

    }

 
    let allCharacterStats = buildCharacterShortStat(allplayercharcters)
    console.log("all dm char" + allDMCharacters)


    return (
        <>
            {//if nameis correct redirect
                redirectNewChar ? <Redirect to={"/newChar/"} /> : <></>
            }
            <div style={{ marginLeft: "auto", marginRight: "auto", width:"200px", textAlign:"center" }}>
                <h1 className="theme-h1-user-page" >{userName}</h1>
                <small className="butt-ton" onClick={() => { setredirectNewChar(true) }}>&nbsp;Create Character&nbsp;</small>
            </div>
            {
                allCharacterStats.map((char, i) => {
                    let tempData = { "Image": char.Image, "characterName": char.Name }

                    return (
                        <div className='char-container' key={"char-" + i} onClick={document.location.url = "/character/"+char.Name}>
                            <ComponentImage details={tempData} />
                            <h2 className="theme-h2-user-page"  onClick={document.location.url = "/character/"+char.Name}>{char.Name} - {char.Level} {char.Class}</h2>
                            <table className="char-stat-table">
                                <thead>
                                    <tr>
                                        <th className="theme-th">HP</th>
                                        <th className="theme-th">Armor Class</th>
                                        <th className="theme-th">Speed</th>
                                        <th className="theme-th">Initiative</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="theme-td">{char.HP}</td>
                                        <td className="theme-td">{char.Stats.ArmorClass}</td>
                                        <td className="theme-td">{char.Stats.Speed}</td>
                                        <td className="theme-td">{char.Stats.Initiative}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                })
            }

            {
                allDMCharacters.map((dm, i) => {
                    console.log("building thing:-: " + dm)
                    let tempData = { "Image": 'none.png', "characterName": dm }
                    return (
                        <div className='char-container' key={"char-" + i}>
                            <DMImage details={tempData} />
                            <h2 className="theme-h2-user-dm-page">Dungeon Master - {dm}</h2>
                        </div>
                    )

                })
            }
        </>
    )
}