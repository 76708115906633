import React, {useState, useEffect} from 'react';


async function testURL(URL){
    const data = { email: 'dean.s.parrott@gmail.com'};
    const response = await fetch(URL,{
        method: 'POST',
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
    });
    const myJson = await response.json()
    return (myJson)
}


export default function Testrest() {

    const [showUser,setshowUser] = useState(false)
    //email = value setEmail = state changer useState = default value
    const [user,setUser] = useState("")

    useEffect(async ()=>{
        const data = { username: 'Dean'};
        const response = await fetch('https://7a74feofgb.execute-api.ap-southeast-2.amazonaws.com/prod/getuser',{
            method: 'POST',
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data)
        });
        const myJson = await response.json()
        console.log(myJson)
        let returnData = JSON.parse(myJson.body)
        console.log(returnData.UserName)
        let user = returnData
        console.log("user object")
        console.log(user)

        setUser(user) //react set state function
        if(user !== ''){
            setshowUser(true)
        }
        else{
            setshowUser(false)
        }
    },[]) //figure out conditional run this will help with multiple components

    console.log ("posting now")      
    

    return (
        <>
            {//if nameis correct redirect
                user.UserName.tostring().length > 1? //ternery statement
                <p>
                Email: {user}
                </p>
                :<p>
                    test with no email
                </p>
            }

            

        </>
    )

}